import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import ExternalRedirect from '../components/ExternalRedirect';
import Heading from '../components/Heading';
import YelpCard from '../components/YelpCard';
import Layout from '../layouts/layout';

function LandingPage(props) {
	return (
		<Layout>
			<div className="landing-page">
				<Heading level="h1" noBorder>
					award-winning championship bbq restaurant
				</Heading>

				{/* <div
					className="menu-section"
					style={{
						width: 600,
						maxWidth: '90%',
						margin: '0 auto',
						display: 'flex'
					}}
				>
					<Img
						fluid={props.data.turkey.childImageSharp.fluid}
						alt="turkey dinner"
						className="hide-xs"
						style={{ width: 200, flexShrink: 0 }}
					/>
					<p
						style={{
							padding: 20,
							minWidth: 0
						}}
					>
						<strong>Order your Thanksgiving dinner with us</strong>
						<br />
						Every November, we smoke turkeys so that you don't have
						to! Get the best Thanksgiving dinner with none of the
						work.
						<br />
						<br />
						<Link to="/turkey">
							Click here to reserve your own turkey now
						</Link>
					</p>
				</div>

				<br /> */}

				<Img
					fluid={props.data.landing1.childImageSharp.fluid}
					alt="brisket sandwich, housemade chips, and beer"
				/>

				<br />

				<p className="quote">
					<i>
						"I honestly am at a loss for superlatives regarding this
						delightful (new) addiction of mine. Honestly... The best
						beef ribs I've ever had. Not even a smidge of an
						exaggeration." -Roy B., San Marcos
					</i>
				</p>

				<Heading>The Food</Heading>

				<p>
					Our standards are high. We proudly serve Brandt Beef, and
					everything, yes, <i>everything</i> - on our menu is cooked
					from scratch right here in our kitchen. We serve everything
					you'd expect including pulled pork, beef ribs, brisket,
					tri-tip, wings, chicken, 1/2lb smoked burgers, and even
					housemade spicy sausage. Enjoy sides like bbq beans, potato
					salad, hand-cut onion rings, sweet peach cobbler, and local
					beer on tap.
				</p>
				<Link to="/menu-vista-downtown">
					Our Menu
					<span role="presentation" aria-hidden="true">
						{' '}
						&gt;
					</span>
				</Link>

				<p className="quote">
					<i>
						"This is by far the best BBQ restaurant that I have ever
						eaten at. I usually order beef ribs and theirs were
						soooo meaty, tender and delicious. The servings are
						huge."
						<br />- Beverly H., Vista
					</i>
				</p>

				<Img
					fluid={props.data.landing2.childImageSharp.fluid}
					alt="burger made from brandt beef patty, pulled pork, onion rings, and pickle with fries"
				/>

				<br />

				<Heading>Locations</Heading>

				<p>
					<ExternalRedirect
						className="location"
						href="https://www.google.com/maps/place/When+Pigs+Fly+BBQ/@33.2026543,-117.2440956,17z/data=!3m1!4b1!4m5!3m4!1s0x80dc7698c2ac7419:0xc05733f1507efd70!8m2!3d33.2026498!4d-117.2419069"
					>
						230 main street, vista, ca
					</ExternalRedirect>
					<br />
					<span style={{ fontSize: '80%' }}>
						<strong>Hours</strong> Mon-Thurs 11am-8pm, Fri-Sat
						11am-10pm, Closed Sunday
					</span>
					<br />
					<a href="tel:+17602957993">
						<span className="sr-only">
							Call our downtown vista location at:{' '}
						</span>
						(760) 295-7993
					</a>
					<br />
					<YelpCard data={props.data.downtownYelp} />
				</p>

				<p>
					<ExternalRedirect
						className="location"
						href="https://www.google.com/maps/place/When+Pigs+Fly+BBQ/@33.217094,-117.22392,16z/data=!4m8!1m2!2m1!1sWhen+Pigs+Fly+BBQ!3m4!1s0x0:0xceb074c420b50f1d!8m2!3d33.2176252!4d-117.2254166"
					>
						1211 e. vista way, vista, ca (temporarily closed)
					</ExternalRedirect>
					<br />
					<span style={{ fontSize: '80%' }}>
						<strong>Hours</strong> Mon-Thurs 6am-7pm, Fri-Sat
						6am-8pm, Closed Sunday
					</span>
					<br />
					<a href="tel:+17609457828">
						<span className="sr-only">
							Call our foothill vista location at:{' '}
						</span>
						(760) 945-7828
					</a>
					<br />
					<YelpCard data={props.data.foothillYelp} />
				</p>

				<p>
					We started out in the side of a Chevron station. (Really
					truly! That's our 1211 E. Vista Way location.) Our food
					quickly became popular enough that we expanded into a fast
					casual, sit-down location in historic downtown Vista.
				</p>

				<Img
					fluid={props.data.landing4.childImageSharp.fluid}
					alt="redneck tacos with coleslaw and pulled pork"
				/>

				<br />

				<Heading>About us</Heading>

				<p>
					As a competition BBQ team, our roots go way back to 2006
					when we took home the first prize ribbon for brisket at the
					very first Stagecoach Country Music Festival bbq competition
					in Palm Desert. Since then, we've placed first numerous
					times for our smoked meats, and have been crowned the Grand
					Champion at one of California's largest BBQ competitions. We
					can't wait to share our love of BBQ with you!
				</p>

				<ExternalRedirect href="http://toasttab.com/when-pigs-fly-bbq-main-street">
					Order Online for pickup
					<span role="presentation" aria-hidden="true">
						{' '}
						&gt;
					</span>
				</ExternalRedirect>
			</div>
		</Layout>
	);
}

export default LandingPage;

export const query = graphql`
	query {
		landing1: file(relativePath: { eq: "landing-1.png" }) {
			childImageSharp {
				fluid(maxWidth: 960) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		}

		landing2: file(relativePath: { eq: "landing-2.png" }) {
			childImageSharp {
				fluid(maxWidth: 960) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		}

		landing4: file(relativePath: { eq: "landing-4.png" }) {
			childImageSharp {
				fluid(maxWidth: 960) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		}

		turkey: file(relativePath: { eq: "turkey1.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 200) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		}

		foothillYelp: yelp {
			business(id: "when-pigs-fly-bbq-vista-3") {
				name
				rating
				url
				review_count
			}
		}

		downtownYelp: yelp {
			business(id: "when-pigs-fly-bbq-vista-8") {
				name
				rating
				url
				review_count
			}
		}
	}
`;

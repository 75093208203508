import React from 'react'

export default function Heading(props) {
	let Heading = props.level || 'h2'

	return (
		<div
			className={`heading-container ${props.noBorder ? 'no-border' : ''}`}
		>
			<Heading className="heading">{props.children}</Heading>
		</div>
	)
}
